import React, { createContext, useState } from 'react';
import { Snackbar } from '@mui/joy';
import { Icon } from '@iconify/react';

// Create a context to manage alerts
const AlertContext = createContext();

const AlertProvider = ({ children }) => {
    // State to manage the alert message
    const [message, setMessage] = useState(null);

    const setAlert = ({ type, message }) => setMessage({ type: type, text: message })

    return (
        <AlertContext.Provider value={{ setAlert }}>
            {children}
            {/* Display Snackbar if there is a message */}
            {message ? (
                <Snackbar
                    autoHideDuration={3000}
                    onClose={() => setMessage(null)}
                    open={Boolean(message)}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    startDecorator={<Icon icon='lucide:info' />}
                    size='lg'
                    color={
                        message?.type === 'info'
                            ? 'primary'
                            : message?.type === 'warning'
                            ? 'warning'
                            : message?.type === 'success'
                            ? 'success'
                            : 'neutral'
                    }
                    variant='soft'
                >
                    {message.text /* Access the text property of the message object */}
                </Snackbar>
            ) : null}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
export { AlertContext };
