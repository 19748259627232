import React, { useContext, useState } from 'react';
import { Modal, ModalDialog, Typography, Stack, Button, Divider, FormControl, FormLabel, Input, Link } from '@mui/joy';
// import { Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import { Grow } from '@mui/material';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { GolfCourseDataContext } from '../datatypes/providers/GolfCourseProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';

const CreateAccountModal = ({ open, onClose, onClickSignInLink, afterSignUp }) => {

    const auth = getAuth()
    const functions = getFunctions()
    const [loading, setLoading] = useState(false)
    const { courseData, courseLoading } = useContext(GolfCourseDataContext)

    // Function to create account
    const createAccount = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            const userCred = await createUserWithEmailAndPassword(auth, e.target.elements.email.value, e.target.elements.password.value)
            const createUserDoc = httpsCallable(functions, "createUserDoc")
            const response = await createUserDoc({
                display_name: e.target.elements.displayName.value,
                email: userCred.user.email,
                uid: userCred.user.uid
            })
            // console.log("Data from createUserDoc", response.data);
            setLoading(false)
            afterSignUp()
        } catch (e) {
            // console.log("Error in LoginModal: ", e);
            setLoading(false)
        }
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Grow style={{ transform: '-50% -50%' }} in={open}>
                    <ModalDialog sx={{ py: 2, pt: 3, maxWidth: '450px', width: '100%' }}>
                        <Stack gap={2}>
                            <Typography level='title-lg' sx={{ textAlign: 'center' }}>Create an OpenSheet account</Typography>

                            <Stack gap={0.75}>
                                <Button color='neutral' variant='outlined' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="flat-color-icons:google" />}>Continue with Google</Button>
                                <Button color='neutral' variant='outlined' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="logos:apple" />}>Continue with Apple</Button>
                            </Stack>

                            <Divider />

                            <form action="" onSubmit={createAccount}>
                                <Stack gap={0.75}>
                                    <FormControl required>
                                        <FormLabel>Your name</FormLabel>
                                        <Input name='displayName' placeholder='Bruce Wayne' />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Email</FormLabel>
                                        <Input name='email' type='email' placeholder='someone@somewhere.com' />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Password</FormLabel>
                                        <Input name='password' type="password" placeholder='Enter your password' />
                                    </FormControl>
                                    <Button color={courseLoading ? "neutral" : courseData.courseTheme} type="submit" loading={loading}>Create your account</Button>
                                </Stack>
                            </form>

                            <Typography level='body-sm'>Already registered? <Link onClick={onClickSignInLink}>Log in here</Link></Typography>

                        </Stack>
                    </ModalDialog>
                </Grow>
            </Modal>
        </>
    )
}

export default CreateAccountModal;