import { extendTheme } from "@mui/joy";

const osTheme = extendTheme({
    fontFamily: {
        display: "Instrument Sans, sans-serif",
        body: "Instrument Sans, sans-serif",
        fallback: "sans-serif"
    },
    colorSchemes: {
        light: {
            palette: {
                main: {
                    50: '#cfbcff',
                    100: '#b69bff',
                    200: '#9e79ff',
                    300: '#845de6',
                    400: '#6a42cb',
                    500: '#5e33be',
                    600: '#5223b2',
                    700: '#460ea7',
                    800: '#3a0092',
                    900: '#22005d',
                    solidBg: 'var(--joy-palette-main-400)',
                    solidActiveBg: 'var(--joy-palette-main-500)',
                    outlinedBorder: 'var(--joy-palette-main-500)',
                    outlinedColor: 'var(--joy-palette-main-700)',
                    outlinedActiveBg: 'var(--joy-palette-main-100)',
                    softColor: 'var(--joy-palette-main-800)',
                    softBg: 'var(--joy-palette-main-200)',
                    softActiveBg: 'var(--joy-palette-main-300)',
                    plainColor: 'var(--joy-palette-main-700)',
                    plainActiveBg: 'var(--joy-palette-main-100)',
                },
                nature: {
                    solidBg: 'var(--joy-palette-nature-400)',
                    solidActiveBg: 'var(--joy-palette-nature-500)',
                    outlinedBorder: 'var(--joy-palette-nature-500)',
                    outlinedColor: 'var(--joy-palette-nature-700)',
                    outlinedActiveBg: 'var(--joy-palette-nature-100)',
                    softColor: 'var(--joy-palette-nature-800)',
                    softBg: 'var(--joy-palette-nature-200)',
                    softActiveBg: 'var(--joy-palette-nature-300)',
                    plainColor: 'var(--joy-palette-nature-700)',
                    plainActiveBg: 'var(--joy-palette-nature-100)',
                },
                ocean: {
                    solidBg: 'var(--joy-palette-ocean-400)',
                    solidActiveBg: 'var(--joy-palette-ocean-500)',
                    outlinedBorder: 'var(--joy-palette-ocean-500)',
                    outlinedColor: 'var(--joy-palette-ocean-700)',
                    outlinedActiveBg: 'var(--joy-palette-ocean-100)',
                    softColor: 'var(--joy-palette-ocean-800)',
                    softBg: 'var(--joy-palette-ocean-200)',
                    softActiveBg: 'var(--joy-palette-ocean-300)',
                    plainColor: 'var(--joy-palette-ocean-700)',
                    plainActiveBg: 'var(--joy-palette-ocean-100)',
                },
                deepblue: {
                    solidBg: 'var(--joy-palette-deepblue-400)',
                    solidActiveBg: 'var(--joy-palette-deepblue-500)',
                    outlinedBorder: 'var(--joy-palette-deepblue-500)',
                    outlinedColor: 'var(--joy-palette-deepblue-700)',
                    outlinedActiveBg: 'var(--joy-palette-deepblue-100)',
                    softColor: 'var(--joy-palette-deepblue-800)',
                    softBg: 'var(--joy-palette-deepblue-200)',
                    softActiveBg: 'var(--joy-palette-deepblue-300)',
                    plainColor: 'var(--joy-palette-deepblue-700)',
                    plainActiveBg: 'var(--joy-palette-deepblue-100)',
                },
                mastersgreen: {
                    solidBg: 'var(--joy-palette-mastersgreen-400)',
                    solidActiveBg: 'var(--joy-palette-mastersgreen-500)',
                    outlinedBorder: 'var(--joy-palette-mastersgreen-500)',
                    outlinedColor: 'var(--joy-palette-mastersgreen-700)',
                    outlinedActiveBg: 'var(--joy-palette-mastersgreen-100)',
                    softColor: 'var(--joy-palette-mastersgreen-800)',
                    softBg: 'var(--joy-palette-mastersgreen-200)',
                    softActiveBg: 'var(--joy-palette-mastersgreen-300)',
                    plainColor: 'var(--joy-palette-mastersgreen-700)',
                    plainActiveBg: 'var(--joy-palette-mastersgreen-100)',
                },
                night: {
                    solidBg: 'var(--joy-palette-night-800)',
                    solidActiveBg: 'var(--joy-palette-night-500)',
                    outlinedBorder: 'var(--joy-palette-night-500)',
                    outlinedColor: 'var(--joy-palette-night-700)',
                    outlinedActiveBg: 'var(--joy-palette-night-100)',
                    softColor: 'var(--joy-palette-night-800)',
                    softBg: 'var(--joy-palette-night-200)',
                    softActiveBg: 'var(--joy-palette-night-300)',
                    plainColor: 'var(--joy-palette-night-700)',
                    plainActiveBg: 'var(--joy-palette-night-100)',
                },
                red: {
                    solidBg: 'var(--joy-palette-red-400)',
                    solidActiveBg: 'var(--joy-palette-red-500)',
                    outlinedBorder: 'var(--joy-palette-red-500)',
                    outlinedColor: 'var(--joy-palette-red-700)',
                    outlinedActiveBg: 'var(--joy-palette-red-100)',
                    softColor: 'var(--joy-palette-red-800)',
                    softBg: 'var(--joy-palette-red-200)',
                    softActiveBg: 'var(--joy-palette-red-300)',
                    plainColor: 'var(--joy-palette-red-700)',
                    plainActiveBg: 'var(--joy-palette-red-100)',
                },
            }
        },

    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: '3px',
                    padding: '8px 16px',
                    ...((["main", "nature", "ocean", "mastersgreen", "deepblue", "night", "red"].includes(ownerState.color)) && {
                        color: 'white',
                        fontWeight: '500'
                    })
                })
            }
        },
        JoyInput: {
            styleOverrides: {
                root: {
                    borderRadius: '3px'
                }
            }
        },
        JoyAlert: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...((["main", "nature", "ocean", "mastersgreen", "deepblue", "night", "red"].includes(ownerState.color) && ownerState.variant === "solid") && {
                        color: 'white'
                    })
                })
            }
        },
        JoySelect: {
            styleOverrides: {
                root:  {
                    borderRadius: '4px',
                    minHeight: '2.7rem'
                }
            }
        },
    },
})

export { osTheme as OpenSheetTheme }
