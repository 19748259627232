import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { OpenSheetTheme } from './theme/OpenSheetTheme';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID,
  useColorScheme as useMaterialColorScheme
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider, useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import AuthProvider from './datatypes/providers/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const materialTheme = materialExtendTheme({
  "colorSchemes": {
    "light": {
      "palette": {
        "green": {
          "50": "#d7ff84",
          "100": "#bbf532",
          "200": "#a0d800",
          "300": "#8abb00",
          "400": "#749e00",
          "500": "#5f8200",
          "600": "#4b6700",
          "700": "#415a00",
          "800": "#384e00",
          "900": "#2e4100",
        }
      }
    },
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px'
        }
      }
    },
    JoyInput: {
      styleOverrides: {
        root: {
          borderRadius: '3px'
        }
      }
    },
  },
})

root.render(
  <React.StrictMode>
    <AuthProvider>
      <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider>
          <App />
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
