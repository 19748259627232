import React, { useContext, useState } from 'react';
import { Button, CircularProgress, Container, DialogTitle, Drawer, IconButton, Link, List, ListItemButton, ModalClose, Sheet, Stack, Typography } from '@mui/joy';
import { AuthContext } from '../datatypes/providers/AuthProvider';
import { OpenSheetTheme } from '../theme/OpenSheetTheme';
import { Icon } from '@iconify/react';
import { useMediaQuery } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useParams } from 'react-router-dom';

const Navbar = ({ theme, onLoginClick }) => {

    const { user, loading } = useContext(AuthContext);
    const { courseID } = useParams();
    const { from } = useParams();
    const [open, setOpen] = useState(false)

    const auth = getAuth()

    return (
        <>
            {/* Navbar */}
            <Sheet color={theme} variant='solid' sx={{ py: '20px' }}>
                <Container>
                    <Stack direction='row' gap={2} alignItems='center' justifyContent={useMediaQuery(OpenSheetTheme.breakpoints.up('md')) ? "start" : 'space-between'}>
                        {from && <Link href={`/course/${from}`}><Typography sx={{ color: 'white' }} level='title-lg'>Book a Tee Time</Typography></Link>}
                        {!from && <Typography sx={{ color: 'white' }} level='title-lg'>Book a Tee Time</Typography>}
                        {loading
                            ? <CircularProgress determinate={false} size='sm' />
                            :
                            user
                                ? <Stack direction='row' justifyContent='space-between' gap={1} sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                                    <Link href={`/dashboard/${courseID ? courseID : from}`} level='body-sm' sx={{ color: 'white' }}>My Tee Times</Link>
                                    <Link onClick={() => signOut(auth)} level='body-sm' sx={{ color: 'white' }}><Button color='neutral' sx={{ color: 'white' }} variant='outlined'>Log out</Button></Link>
                                </Stack>
                                : <Button color='neutral' sx={{ color: 'white' }} onClick={onLoginClick} variant='outlined'>Log in</Button>}

                        <IconButton onClick={() => setOpen(true)} sx={{ display: { xs: 'initial', md: 'none' } }} variant='soft' color='primary'><Icon fontSize="35px" icon='jam:menu' /></IconButton>


                    </Stack>
                </Container>
            </Sheet >

            {/* Mobile navigation menu */}
            < Drawer
                open={open}
                onClose={() => setOpen(false)}
                anchor='top'
                size='sm'
                slotProps={{
                    content: {
                        sx: {
                            bgcolor: 'transparent',
                            p: { md: 3, sm: 0 },
                            boxShadow: 'none',
                        },
                    },
                }}>
                <Sheet
                    sx={{
                        m: 2,
                        borderRadius: 'md',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}>
                    <DialogTitle>OpenSheet</DialogTitle>
                    <ModalClose />

                    {loading
                        ? <CircularProgress determinate={false} size='sm' />
                        :
                        user
                            ? <List>
                                <Link href={`/dashboard/${from ? from : courseID}`}><ListItemButton>My Tee Times</ListItemButton></Link>
                                <ListItemButton onClick={() => signOut(auth)}>Log out</ListItemButton>
                            </List>
                            : <List>
                                <Link href="/login" sx={{color: 'white', textDecoration: 'none', mb: 1}}><ListItemButton size='lg' variant='solid' sx={{borderRadius: '4px', width: '100%'}} color={theme}>Sign in</ListItemButton></Link>
                                <Link sx={{color: 'white', textDecoration: 'none'}}><ListItemButton size='lg' variant='outlined' sx={{borderRadius: '4px', width: '100%'}} color={theme}>Create an account</ListItemButton></Link>
                            </List>}


                </Sheet>
            </Drawer >
        </>
    )
}

export default Navbar;