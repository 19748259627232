// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1trq_qMKGOUDlDUJ0LbTZCg3SjfkMrD0",
  authDomain: "opensheet5000.firebaseapp.com",
  projectId: "opensheet5000",
  storageBucket: "opensheet5000.appspot.com",
  messagingSenderId: "228508543617",
  appId: "1:228508543617:web:3bc8c420573b5823d47b80",
  measurementId: "G-YK9YJ9RKBJ"
};

// Initialize Firebase
initializeApp(firebaseConfig);