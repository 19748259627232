import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoginModal from '../ui/LoginModal';
import CreateAccountModal from '../ui/CreateAccountModal';
import Navbar from '../ui/Navbar';
import { GolfCourseDataContext } from '../datatypes/providers/GolfCourseProvider';
import { Alert, Button, Container, DialogContent, DialogTitle, Divider, FormControl, FormLabel, Link, Modal, ModalClose, ModalDialog, Option, Select, Sheet, Skeleton, Stack, Table, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import TeeTimeCard from '../ui/TeeTimeCard';
import { Grow, useMediaQuery } from '@mui/material';
import { AuthContext } from '../datatypes/providers/AuthProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet';
import logo from '../assets/ll.png';
import { AlertContext } from '../ui/AlertProvider';
import SkeletonTeeTimeCard from '../ui/SkeletonTeeTimeCard';
import { OpenSheetTheme } from '../theme/OpenSheetTheme';
var minMax = require('dayjs/plugin/minMax')
dayjs.extend(minMax)

const ViewTeeSheet = () => {

    const { courseID } = useParams()
    const { courseData, courseLoading } = useContext(GolfCourseDataContext)
    const { user, authLoading } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)

    const auth = getAuth()
    const functions = getFunctions()
    const provider = new GoogleAuthProvider()

    // Auth states
    const [holdIsPending, setHoldIsPending] = useState(false)
    const [teeTimeOnHold, setTimeOnHold] = useState(null)
    const [googleAuthLoading, setGoogleAuthLoading] = useState(false)

    // State variables for filtering form
    const [players, setPlayers] = useState(4)
    const [cart, setCart] = useState("yes")
    const [holes, setHoles] = useState(18)
    const [date, setDate] = useState(dayjs.max(dayjs(), dayjs(courseData?.min_date?._seconds * 1000)))

    // UI states
    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [createAccountModalOpen, setCAccModalOpen] = useState(false)
    const [selectedTeeTime, setSelectedTeeTime] = useState(null)

    // State variables for tee sheet
    const [bookingLoading, setBookingLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [teeSheet, setTeeSheet] = useState(null)

    // Function to log in using Google
    const loginWithGoogle = async () => {
        setGoogleAuthLoading(true)
        try {
            const userCred = await signInWithPopup(auth, provider)
            try {
                const createUserDoc = httpsCallable(functions, "createUserDoc")
                const result = await createUserDoc({
                    display_name: userCred.user.displayName,
                    email: userCred.user.email,
                    uid: userCred.user.uid
                })
                if(result.data.message === "Successfully created user.") { setAlert({ type: 'success', message: "Successfully logged in!" }) }
            } catch (e) {
                console.log("Error adding user document: ", e);
                setAlert({ type: 'warning', message: "There was an error setting up your account." })
            }
            setGoogleAuthLoading(false)
        } catch (e) {
            console.log(e);
            setAlert({ type: 'warning', message: "We couldn't log you in." })
        }
        setGoogleAuthLoading(false)
        setLoginModalOpen(false)
        setCAccModalOpen(false)
        checkPendingBooking()
    }

    // Function to check if booking is pending after logging in/signing up
    const checkPendingBooking = () => {
        setCAccModalOpen(false)
        setLoginModalOpen(false)
        if (holdIsPending) {
            setSelectedTeeTime(teeTimeOnHold)
            holdSlot(teeTimeOnHold)
        }
    }

    // Function to fetch tee sheet from the server
    const getTeeSheet = async () => {
        setLoading(true)
        setTeeSheet(null)
        const getTeeTimeMapForDate = httpsCallable(functions, "getTeeTimeMapForDate")
        try {
            const response = await getTeeTimeMapForDate({
                courseID: courseID,
                date: date.format("MM-DD-YYYY")
            })
            if (response.message) { return }
            setTeeSheet(response.data.times)
            // console.log(response.data);
            // setAlert({ type: "success", message: "Got the tee sheet!" })
        } catch (e) {
            console.log(e.message);
        }
        setLoading(false)
    }

    // Function to hold a slot
    const holdSlot = async (time) => {
        if (!user) { setLoginModalOpen(true); setHoldIsPending(time); return }
        setSelectedTeeTime(time)
        var slots = []
        time.slots.forEach(slot => {
            if (!slot.booking.isBooked) { slots.push(slot.slotNumber) }
        })
        slots = slots.slice(0, players)

        const initiateHoldOnTeeTime = httpsCallable(functions, "initiateHoldOnTeeTime")

        try {
            const result = await initiateHoldOnTeeTime({
                teesheetsDocRef: `${courseData.teeSheetRef.replace("teesheets/", "").replace("/dailyTeeTimeMaps", "")}`,
                dailyTeeTimeMapsDocRef: date.format("MM-DD-YYYY"),
                userId: user.uid,
                time: time.time,
                slotNumbers: [1, 2, 3, 4]
            })

            console.log(result.data);
            setAlert({ type: 'success', message: "Your slot is being held." })
        } catch (e) {
            console.log(e);
            setAlert({ type: 'warning', message: "There was an issue holding the slot." })
        }
    }

    // Function to book a slot
    const bookTime = async (time) => {
        console.log(time);
        setBookingLoading(true)
        var slots = []
        time.slots.forEach(slot => {
            if (!slot.booking.isBooked) { slots.push(slot.slotNumber) }
        })
        slots = slots.slice(0, players)
        slots.forEach((num, index) => slots[index] = num.toString())

        const createActiveBookingOnCall = httpsCallable(functions, "createActiveBookingOnCall")

        try {
            await createActiveBookingOnCall({
                teesheetsDocRef: courseData.teeSheetRef.replace("teesheets/", "").replace("/dailyTeeTimeMaps", ""),
                dailyTeeTimeMapsDocRef: date.format("MM-DD-YYYY"),
                dateOfPlay: date.format("MM-DD-YYYY"),
                time: time.time,
                slotNumber: slots[0].toString(),
                cartFeeNine: time.slots[3].cartFeeNine,
                cartFeeEighteen: time.slots[3].cartFeeEighteen,
                eighteenHolePrice: time.slots[3].eighteenHolePrice,
                nineHolePrice: time.slots[3].nineHolePrice,
                bookedByUser: user.uid,
                courseName: courseData.courseName,
                playerEmail: user.email,
                playerFirstName: "John",
                playerLastName: "Doe",
                playerPhone: "1234567890",
                playerUserRef: user.uid,
                partySize: players,
                numberOfHolesBooked: holes,
                slotsBookedTogether: slots
            })
            // try {
            //     const result = await sendBookingEmail({
            //         email: user.email,
            //         display_name: user.displayName,
            //         courseName: courseData.courseName,
            //         dateOfPlay: date.format("D MMM YYYY"),
            //         time: time.time
            //     })
            //     console.log(result);
            // } catch (e) {
            //     console.log("There was an error sending the email: ", e)
            // }
            setBookingLoading(false)
            setSelectedTeeTime(null)
            setHoldIsPending(null)
            setAlert({ type: 'success', message: "Your tee time has been booked!" })
            getTeeSheet()
        } catch (e) {
            console.log(e);
            setAlert({ type: 'warning', message: "There was an issue booking your tee time." })
            setBookingLoading(false)
        }
    }

    // When courseData loads, change the date on the DatePicker
    useEffect(() => setDate(dayjs.max(dayjs(), dayjs(courseData?.min_date?._seconds * 1000))), [courseData])
    // useEffect(() => console.log(courseData), [courseData])

    // When date changes, fetch the tee sheet from the server
    useEffect(() => {
        // console.log(date.format("MM-DD-YYYY"));

        if (date.format("MM-DD-YYYY") != "Invalid Date" || date.format("MM-DD-YYYY") !== null) { getTeeSheet() }

    }, [date])

    return (
        <>
            <Helmet>
                <title>Book a Tee Time</title>
            </Helmet>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* Modal to log in */}
                <LoginModal
                    gAuth={loginWithGoogle}
                    gLoading={googleAuthLoading}
                    open={loginModalOpen}
                    onClose={() => setLoginModalOpen(false)}
                    onClickSignUpLink={() => { setLoginModalOpen(false); setCAccModalOpen(true) }} />

                {/* Modal to create account */}
                <CreateAccountModal
                    open={createAccountModalOpen}
                    onClose={() => setCAccModalOpen(false)}
                    onClickSignInLink={() => { setCAccModalOpen(false); setLoginModalOpen(true) }} />

                {/* Modal to show selected tee time */}
                <Modal open={selectedTeeTime} onClose={() => setSelectedTeeTime(null)}>
                    <Grow style={{ transform: '-50% -50%' }} in={selectedTeeTime}>
                        <ModalDialog sx={{ maxWidth: '500px' }}>
                            <ModalClose />
                            <DialogTitle>Book time</DialogTitle>
                            <DialogContent>
                                <Alert color='primary' variant='soft'>This slot will be held for 2 minutes. Please complete the reservation process by {dayjs().format("HH.mm")} pm.</Alert>

                                <Stack gap={1.5} sx={{ mt: 2 }}>
                                    <Stack>
                                        <Typography level='body-sm'>Lost Lake Golf Club</Typography>
                                        <Typography level='title-md'>Lost Lake Golf Club - {holes} holes</Typography>
                                    </Stack>

                                    <Stack direction='row' gap={4}>
                                        <Stack>
                                            <Typography level='body-sm'>Date</Typography>
                                            <Typography level='title-md'>{date?.format("MMM D, YYYY")}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography level='body-sm'>Time</Typography>
                                            <Typography level='title-md'>{selectedTeeTime ? selectedTeeTime.time : "loading..."}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack direction='row' gap={4} >
                                        <Stack justifyContent='space-between'>
                                            <Typography level='body-sm'>No. of players</Typography>
                                            <Typography level='title-md'>{players}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography level='body-sm'>Cart</Typography>
                                            <Typography level='title-md'>{cart === "yes" ? "Yes" : "No"}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack>
                                        <Typography level='body-sm'>Price</Typography>
                                        <Typography level='title-lg'>{players} x ${holes === 18 ? selectedTeeTime?.slots[3].eighteenHolePrice : selectedTeeTime?.slots[3].nineHolePrice}</Typography>
                                    </Stack>

                                    <Typography level='body-xs'>By booking the slot, you agree to the <Link>booking rules.</Link></Typography>

                                    {/* <Button loading={loading} sx={{ width: '100%' }} onClick={() => { setLoginModalOpen(true) }}>Book time</Button> */}
                                    <Button loading={bookingLoading} color={courseLoading ? "neutral" : courseData.courseTheme} onClick={() => bookTime(selectedTeeTime)} sx={{ width: '100%' }}>Book time</Button>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </Grow>
                </Modal>


                {/* Navbar */}
                <Navbar onLoginClick={() => setLoginModalOpen(true)} theme={courseLoading ? "night" : courseData.courseTheme} />

                <Container sx={{ mt: 3 }}>
                    {/* Any messages from the golf course */}
                    {courseData?.course_message
                        ? courseData?.course_message.map(message => {
                            return <Alert color={courseLoading ? "neutral" : courseData.courseTheme} sx={{ mb: 2 }} variant='solid' size='lg' startDecorator={<Icon icon='lucide:info' />}>{message}</Alert>
                        })
                        : null}

                    {/* Course details - name, address etc. */}
                    <Stack direction={useMediaQuery(OpenSheetTheme.breakpoints.up("md")) ? "row" : "column"} gap={2} justifyContent='space-between'>
                        <Stack sx={{ flexGrow: 1 }}>
                            <Typography level='title-md'>Course</Typography>
                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                <Stack>
                                    <Typography level='h2' sx={{ fontFamily: 'Bebas Neue', letterSpacing: 0, position: 'relative', overflow: 'hidden' }}>{courseData ? null : <Skeleton loading={true} />}{courseData ? courseData.courseName : "Loading............................"}</Typography>
                                    <Typography level='body-sm' sx={{ position: 'relative', overflow: 'hidden' }}>{courseData ? null : <Skeleton loading={true} />}{courseData ? courseData.courseAddress : "Loading..."}</Typography>
                                </Stack>

                                <img src={logo} style={{ maxHeight: '60px' }} alt="" />
                            </Stack>
                        </Stack>

                        <Stack sx={{ flexGrow: 1 }}>
                            <Typography level='title-md'>Date</Typography>
                            <DatePicker value={date} format="MMM D, YYYY" onChange={(e) => setDate(e)} disablePast minDate={dayjs(courseData?.min_date?._seconds * 1000)} maxDate={dayjs(courseData?.max_date?._seconds * 1000)} disabled={courseLoading} sx={{ mt: 0.5 }} />
                        </Stack>
                    </Stack>

                    <Stack direction='row' gap={2} sx={{ mt: 2 }}>
                        {/* No. of players */}
                        <FormControl required sx={{ flexGrow: 1 }}>
                            <FormLabel>Players</FormLabel>
                            <Select name='players' defaultValue={4} value={players} onChange={(e) => setPlayers(Number(e.target.innerText))}>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                            </Select>
                        </FormControl>

                        {/* No. of holes */}
                        <FormControl required sx={{ flexGrow: 1 }}>
                            <FormLabel>No. of holes</FormLabel>
                            <Select name='holes' value={holes} onChange={(e) => setHoles(Number(e.target.innerText.toLowerCase()))} defaultValue={18}>
                                <Option value={9}>9</Option>
                                <Option value={18}>18</Option>
                            </Select>
                        </FormControl>

                        {/* Cart required? */}
                        <FormControl required sx={{ flexGrow: 1 }}>
                            <FormLabel>Cart?</FormLabel>
                            <Select name='cart' value={cart} onChange={(e) => setCart(e.target.innerText.toLowerCase())} defaultValue={'yes'}>
                                <Option value={'yes'}>Yes</Option>
                                <Option value={'no'}>No</Option>
                            </Select>
                        </FormControl>
                    </Stack>

                    <Divider sx={{ my: 4 }} />

                    {/* ------------------------------------------------------------------------------------------------- */}
                    {(date && !loading && !courseLoading) ? <Typography level='h3'>Tee times for {date.format("MMM D, YYYY")}</Typography> : null}
                    {(loading && !courseLoading) ? <Typography level='h3'>Loading tee times...</Typography> : null}

                    <Stack gap={1} sx={{ mt: 2, pb: 4 }}>
                        {(teeSheet && teeSheet.message !== "No data")
                            ? teeSheet?.times.map((time, index) => {
                                return <TeeTimeCard
                                    holes={holes}
                                    persons={players}
                                    teeTime={time}
                                    key={index}
                                    onButtonClick={() => { holdSlot(time) }} />
                            })
                            : null}

                        {/* Skeletons when the tee times are loading */}
                        {(loading && !courseLoading) && <>
                            <SkeletonTeeTimeCard />
                            <SkeletonTeeTimeCard />
                            <SkeletonTeeTimeCard />
                            <SkeletonTeeTimeCard />
                        </>}
                    </Stack>

                </Container>

            </LocalizationProvider>
        </>
    )
}

export default ViewTeeSheet;