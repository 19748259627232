import './App.css';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy';
import { OpenSheetTheme } from './theme/OpenSheetTheme';
import './firebase/init';
import ViewTeeSheet from './routes/ViewTeeSheet';
import GolfCourseProvider from './datatypes/providers/GolfCourseProvider';
import UserDashboard from './routes/UserDashboard';
import Login from './routes/Login.jsx';
import ProtectedRoute from './datatypes/providers/ProtectedRoute.jsx';
import AlertProvider from './ui/AlertProvider.jsx';
// import ThemeProvider from './theme/ThemeProvider.jsx';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to='/course/LostLakeGolfClubX342' />
    },
    {
      path: '/course/:courseID',
      element: <GolfCourseProvider><ViewTeeSheet /></GolfCourseProvider>
    },
    {
      path: '/dashboard/:from',
      element: <ProtectedRoute divertTo={<Navigate to='/login' />}><GolfCourseProvider><UserDashboard /></GolfCourseProvider></ProtectedRoute>
    },
    {
      path: '/dashboard',
      element: <ProtectedRoute divertTo={<Navigate to='/login' />}><GolfCourseProvider><UserDashboard /></GolfCourseProvider></ProtectedRoute>
    },
    {
      path: '/login',
      element: <ProtectedRoute divertTo={<Login />}><Navigate to='/' /></ProtectedRoute>
    },
  ])

  return (
    <CssVarsProvider theme={OpenSheetTheme}>
      {/* <ThemeProvider> */}
        <AlertProvider>
          < RouterProvider router={router} >

          </RouterProvider >
        </AlertProvider>
      {/* </ThemeProvider> */}
    </CssVarsProvider>
  );
}

export default App;
