import React, { useContext, useState } from 'react';
import { Modal, ModalDialog, Sheet, ModalClose, Typography, Stack, Button, Divider, FormControl, FormLabel, Input, Link } from '@mui/joy';
// import { Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import { Grow } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { GolfCourseDataContext } from '../datatypes/providers/GolfCourseProvider';
import { AlertContext } from './AlertProvider';

const LoginModal = ({ open, onClose, onClickSignUpLink, gAuth, gLoading }) => {

    const auth = getAuth()
    const [loading, setLoading] = useState(false);
    const { courseData, courseLoading } = useContext(GolfCourseDataContext)
    const { setAlert } = useContext(AlertContext)

    // Function to log in with email and password
    const login = async (e) => {
        e.preventDefault()
        setLoading(true);
        try {
            const userCred = await signInWithEmailAndPassword(auth, e.target.elements.email.value, e.target.elements.password.value)
            setLoading(false)
            onClose()
        } catch (e) {
            console.log(e.code);
            if(e.code === "auth/user-not-found" || e.code === "auth/wrong-password") { setAlert({ type: 'warning', message: "Invalid email or password" }) } 
            else { setAlert({ type: 'warning', message: "There was an unknown error." }) }
            setLoading(false)
        }
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Grow style={{ transform: '-50% -50%' }} in={open}>
                    <ModalDialog sx={{ py: 2, pt: 3, maxWidth: '450px', width: '100%' }}>
                        <Stack gap={2}>
                            <Typography level='title-lg' sx={{ textAlign: 'center' }}>Log in to OpenSheet</Typography>

                            <Stack gap={0.75}>
                                <Button color='neutral' variant='outlined' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="flat-color-icons:google" />} onClick={() => gAuth()} loading={gLoading}>Continue with Google</Button>
                                <Button color='neutral' variant='outlined' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="logos:apple" />}>Continue with Apple</Button>
                            </Stack>

                            <Divider />

                            <form action="" onSubmit={login}>
                                <Stack gap={1.5}>
                                    <FormControl required>
                                        <FormLabel>Email</FormLabel>
                                        <Input name='email' type='email' placeholder='someone@somewhere.com' />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Password</FormLabel>
                                        <Input name='password' type='password' placeholder='Enter your password' />
                                    </FormControl>
                                    <Button color={courseLoading ? "neutral" : courseData.courseTheme} type="submit" loading={loading}>Log in</Button>
                                </Stack>
                            </form>

                            <Typography level='body-sm'>New user? <Link onClick={onClickSignUpLink}>Create an account</Link></Typography>

                        </Stack>
                    </ModalDialog>
                </Grow>
            </Modal>
        </>
    )
}

export default LoginModal;