import React, { useContext, useEffect, useState } from 'react';
import { Button, Sheet, Stack, Typography } from '@mui/joy';
import { Icon } from '@iconify/react';
// import { ThemeContext } from '../theme/ThemeProvider';
import { GolfCourseDataContext } from '../datatypes/providers/GolfCourseProvider';

const TeeTimeCard = ({ teeTime, persons, onButtonClick, holes }) => {

    const [freeSlots, setFreeSlots] = useState([]);
    const { courseData, courseLoading } = useContext(GolfCourseDataContext)
     
    useEffect(() => {
        setFreeSlots(teeTime.slots.filter(slot => !(slot.booking.isBooked || slot.isPending || slot.isBlocked || slot.isBooked)))
    }, [])

    return (
        <>
        {/* Need to improve the below condition */}
            {!(freeSlots.length >= persons)
                ? null
                : <Sheet variant='outlined' sx={{ p: 1.5, borderRadius: '4px' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack>
                            <Typography level='h3'>{teeTime.time}</Typography>
                            <Typography level='body-sm'>{holes} holes | {persons} {persons > 1 ? "players" : "player"}</Typography>
                        </Stack>
                        <Stack gap={0.5}>
                            <Stack direction='row' gap={1.5} alignItems='center' justifyContent='end'>
                                <Typography level='h3'>{
                                    (holes === 18
                                        ? (teeTime.slots[3].eighteenHolePrice ? `$${teeTime.slots[3].eighteenHolePrice}` : null)
                                        : (teeTime.slots[3].nineHolePrice ? `$${teeTime.slots[3].nineHolePrice}` : null))
                                }</Typography>
                                <Stack gap={0.5} direction='row' alignItems='center'>
                                    <Icon icon="mdi:golf-cart" />
                                    <Typography level='title-md' sx={{ fontStyle: 'italic' }}>{(teeTime.slots[3].cartFee === null || teeTime.slots[3].cartFee == undefined) ? "Inc." : `$${teeTime.slots[3].cartFee}`}</Typography>
                                </Stack>
                            </Stack>
                            <Button onClick={onButtonClick} size='sm' color={courseLoading ? "neutral" : courseData.courseTheme} >Reserve&nbsp;{persons}&nbsp;{persons > 1 ? "players" : "player"}</Button>
                        </Stack>
                    </Stack>
                </Sheet>}
        </>
    )
}

export default TeeTimeCard;