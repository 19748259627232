import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ divertTo, children }) => {

    const { user, userData, bookings, loading, bookingsLoading } = useContext(AuthContext)

    return (
        <>
            {!loading
                ? <>{ user ? children : divertTo }</>
                : null}
        </>
    )
}

export default ProtectedRoute;