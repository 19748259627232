import React, { createContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null)
    const [bookings, setBookings] = useState([])
    const [bookingsLoading, setBookingsLoading] = useState(true)

    const auth = getAuth();
    const functions = getFunctions()
    const getUserDataByUid = httpsCallable(functions, "getUserDataByUid")
    const getBookingByRef = httpsCallable(functions, "getBookingByRef")

    const getData = async (user = auth.currentUser) => {
        try {
            setUser(user);
            const data = await getUserDataByUid({ uid: user.uid });
            console.log(data);
            setUserData(data.data.data);
            setLoading(false);
    
            // Use map and Promise.all to wait for all async operations to complete
            const bookingsArray = await Promise.all(
                data.data.data.myTeeTimes.map(async (ref) => {
                    const response = await getBookingByRef({ bookingRef: ref });
                    return response.data;
                })
            );
    
            console.log("Bookings array length: ", bookingsArray.length);
            setBookings(bookingsArray);
            setBookingsLoading(false);
        } catch (e) {
            console.log("Error in AuthProvider: ", e);
            setLoading(false);
        }
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user)
            if (user) { getData(user) }
            if (!user) { setLoading(false) }
        })
    }, [])

    return <AuthContext.Provider value={{ user, userData, bookings, loading, bookingsLoading, refreshUserData: getData }}>
        {children}
    </AuthContext.Provider>

}


export default AuthProvider;
export { AuthContext };