import React from 'react';
import { Button, Sheet, Skeleton, Stack, Typography } from '@mui/joy';
import { Icon } from '@iconify/react';

const SkeletonTeeTimeCard = () => {

    return (
        <>
            <Sheet variant='outlined' sx={{ borderRadius: '4px', position: 'relative', overflow: 'hidden' }}>
                <Skeleton loading={true} animation='wave' />
                <Stack direction='row' justifyContent='space-between' sx={{p: 1.5}}>
                    <Stack>
                        <Typography level='h3'>8:30 AM</Typography>
                        <Typography level='body-sm'>18 holes | 4 players</Typography>
                    </Stack>
                    <Stack gap={0.5}>
                        <Stack direction='row' gap={1.5} alignItems='center' justifyContent='end'>
                            <Typography level='h3'>$55</Typography>
                            <Stack gap={0.5} direction='row' alignItems='center'>
                                <Icon icon="mdi:golf-cart" />
                                <Typography level='title-md' sx={{ fontStyle: 'italic' }}>Inc.</Typography>
                            </Stack>
                        </Stack>
                        <Button size='sm' >Reserve 4 players</Button>
                    </Stack>
                </Stack>
            </Sheet>
        </>
    )
}

export default SkeletonTeeTimeCard;