import axios from 'axios';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const GolfCourseDataContext = createContext(); // Context that provides golf course data

const GolfCourseProvider = ({ children }) => {

    // Themes object
    // const themes = {
    //     main: "50: '#cfbcff'; 100: '#b69bff'; 200: '#9e79ff'; 300: '#845de6'; 400: '#6a42cb'; 500: '#5e33be'; 600: '#5223b2'; 700: '#460ea7'; 800: '#3a0092'; 900: '#22005d';",
    //     green: '50: "#d7ff84"; 100: "#bbf532"; 200: "#a0d800"; 300: "#8abb00"; 400: "#749e00"; 500: "#5f8200"; 600: "#4b6700"; 700: "#415a00"; 800: "#384e00"; 900: "#2e4100"' ,
    //     blue: ' 50: "#ffefd1"; 100: "#ffdf96"; 200: "#f4bf19"; 300: "#d4a400"; 400: "#b48b00"; 500: "#947200"; 600: "#765a00"; 700: "#594400"; 800: "#3e2e00"; 900: "#251a00" '
    // }

    const functions = getFunctions()

    const [courseData, setData] = useState(null)
    const [courseLoading, setLoading] = useState(true)

    const { courseID } = useParams()
    const { from } = useParams()

    const getData = async () => {
        const getCourseData = httpsCallable(functions, "getCourseData")
        try {
            const data = await getCourseData({
                courseID: courseID ? courseID : from
            })
            setData(data.data)
            setLoading(false)
        } catch (e) {
            console.log("There was an error: ", e);
        }
    }

    useEffect(() => {
        getData()
    }, [])

    // useEffect(() => {
    //     if(courseData) {
    //         document.querySelector(":root").setAttribute("style", (themes.blue))
    //     }
    // }, [courseData])

    return (
        <>
            <GolfCourseDataContext.Provider value={{ courseData, courseLoading }}>
                {children}
            </GolfCourseDataContext.Provider>
        </>
    )
}

export default GolfCourseProvider;
export { GolfCourseDataContext }