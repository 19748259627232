import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../ui/Navbar';
import { Button, CircularProgress, Container, DialogTitle, Divider, FormControl, FormLabel, Input, Link, Modal, ModalClose, ModalDialog, Sheet, Skeleton, Stack, Typography } from '@mui/joy';
import { AuthContext } from '../datatypes/providers/AuthProvider';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { getAuth, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { GolfCourseDataContext } from '../datatypes/providers/GolfCourseProvider';
import { AlertContext } from '../ui/AlertProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useMediaQuery } from '@mui/material';
import { OpenSheetTheme } from '../theme/OpenSheetTheme';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const UserDashboard = () => {

    const { user, userData, bookings, loading, bookingsLoading, refreshUserData } = useContext(AuthContext)
    const { courseData, courseLoading } = useContext(GolfCourseDataContext)
    const { setAlert } = useContext(AlertContext)

    const [currentBooking, setCurrentBooking] = useState(null)
    const [cancelInProgress, setCancelInProgress] = useState(false)
    const [updatingProfile, setUpdatingProfile] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const functions = getFunctions()
    const auth = getAuth()

    const { from } = useParams()

    const cancelBooking = async () => {
        setCancelInProgress(true)
        const cancelBookingOnCall = httpsCallable(functions, "cancelBookingOnCall")
        try {
            await cancelBookingOnCall({
                teesheetsDocRef: courseData.teeSheetRef.replace("teesheets/", "").replace("/dailyTeeTimeMaps", ""),
                dailyTeeTimeMapsDocRef: dayjs(currentBooking.dateOfPlay).format("MM-DD-YYYY"),
                slotNumbers: currentBooking.slotsBookedTogether
            })

            setCancelInProgress(false)
            setAlert({ type: 'success', message: 'Your booking has been cancelled.' })
        } catch (e) {
            console.log(e)
            setAlert({ type: 'warning', message: 'There was an error cancelling your booking.' })
            setCancelInProgress(false)
        }
    }

    const changePhoneNumber = async (e) => {
        e.preventDefault()
        setUpdatingProfile(true)
        const createUserDoc = httpsCallable(functions, "createUserDoc")
        try {
            const result = await createUserDoc({
                uid: user.uid,
                phone_number: e.target.elements.phone_number.value
            })
            // console.log(result)
            setUpdatingProfile(false)
            setModalOpen(false)
            refreshUserData()
            setAlert({ type: 'success', message: 'Your profile has been updated.' })
        } catch (e) {
            console.log(e)
            setAlert({ type: 'warning', message: 'There was an error updating your profile.' })
            setUpdatingProfile(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>OpenSheet | Dashboard</title>
            </Helmet>

            {/* Modal to show booking */}
            <Modal open={Boolean(currentBooking)} onClose={() => setCurrentBooking(null)}>
                <ModalDialog sx={{ maxWidth: '400px', width: '100%' }}>
                    <ModalClose />
                    <DialogTitle>View booking</DialogTitle>
                    <Stack gap={1.5}>
                        <Stack>
                            <Typography level='title-sm'>Course</Typography>
                            <Typography level='title-lg'>{currentBooking?.courseName}</Typography>
                            <Typography level='body-sm'>{currentBooking?.numberOfHolesBooked} holes</Typography>
                        </Stack>

                        <Stack direction='row' justifyContent='space-between'>
                            <Stack>
                                <Typography level='title-sm'>Date of play</Typography>
                                <Typography level='title-lg'>{dayjs(currentBooking?.dateOfPlay).format("MMM D, YYYY")}</Typography>
                            </Stack>
                            <Stack>
                                <Typography level='title-sm'>Time</Typography>
                                <Typography level='title-lg'>{currentBooking?.time}</Typography>
                            </Stack>
                            <Stack>
                                <Typography level='title-sm'>Players</Typography>
                                <Typography level='title-lg'>{currentBooking?.bookingGroupSize} {currentBooking?.bookingGroupSize > 1 ? "players" : 'player'} x ${currentBooking?.numberOfHolesBooked === 18 ? currentBooking?.eighteenHolePrice : currentBooking?.nineHolePrice}</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' gap={1}>
                            <Button onClick={() => setAlert({ type: 'info', message: "Haven't worked on that yet!" })} variant='soft' color='neutral' sx={{ flexGrow: 1 }}>Edit booking</Button>
                            <Button variant='soft' loading={cancelInProgress} onClick={cancelBooking} color='danger' sx={{ flexGrow: 1 }}>Cancel booking</Button>
                        </Stack>
                    </Stack>
                </ModalDialog>
            </Modal>

            {/* Modal to change phone number */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <ModalDialog sx={{ maxWidth: '400px', width: '100%' }}>
                    <ModalClose />
                    <DialogTitle>Change phone number</DialogTitle>
                    <form action="" onSubmit={changePhoneNumber}>
                        <Stack mt={1} gap={2}>
                            <FormControl required>
                                <FormLabel>Phone number</FormLabel>
                                <Input readOnly={updatingProfile} name="phone_number" type='tel' variant='soft' placeholder='Enter your new phone number' />
                            </FormControl>
                            <Button type='submit' loading={updatingProfile} color={courseLoading ? "night" : courseData.courseTheme}>Change your phone number</Button>
                        </Stack>
                    </form>

                </ModalDialog>
            </Modal>

            <Navbar theme={courseLoading ? "night" : courseData.courseTheme} />
            <Container sx={{ mt: 6 }}>
                {/* <UserDataProvider uid={user?.uid}> */}
                <Sheet color='plain' sx={{ textAlign: 'center', mx: 'auto' }}>
                    <img style={{ width: '50px', height: '50px', borderRadius: '60px' }} src={user?.photoURL} />
                    <Typography level='h2' sx={{ overflow: 'hidden', position: 'relative' }}>
                        <Skeleton loading={loading} />
                        {user ? user.displayName : "Loading..."}
                    </Typography>
                    <Typography level='body-md' sx={{ overflow: 'hidden', position: 'relative' }}>
                        <Skeleton loading={loading} />
                        {user ? user.email : "Loading..."}
                    </Typography>
                    <Typography level='body-md' sx={{ overflow: 'hidden', position: 'relative' }}>
                        <Skeleton loading={loading} />
                        {userData ? userData.phone_number.replace(".", " ") : "Loading..."}
                        <Link level='body-sm' sx={{ ml: 1 }} onClick={() => setModalOpen(true)}>Change</Link>
                    </Typography>
                    {!userData.phone_number && <Link level='body-sm' onClick={() => setModalOpen(true)}>Add a phone number</Link>}
                    <Stack direction='row' sx={{ mt: 1 }} justifyContent='center' gap={1}>
                        {from ? <Link href={`/course/${from}`}><Button variant='outlined' color='neutral'>Back to tee sheet</Button></Link> : null}
                        <Button variant='soft' color='danger' onClick={() => signOut(auth)}>Log out</Button>
                    </Stack>
                </Sheet>

                <Divider sx={{ my: 3 }} />

                {/* View for large screens */}
                <Stack direction={useMediaQuery(OpenSheetTheme.breakpoints.up("md")) ? "row" : "column-reverse"} gap={2} >
                    <Stack sx={{ flexGrow: 1 }}>
                        <Typography level='title-lg'>Upcoming tee times</Typography>
                        <Stack sx={{ mt: 1 }} gap={1}>
                            {/* Loading state */}
                            {bookingsLoading ? <Stack direction='row' gap={1}><Typography level='body-sm'>Loading...</Typography><CircularProgress determinate={false} size='sm' color='neutral' /></Stack> : null}
                            {/* {console.log(bookings.length)} */}

                            {bookings.length !== 0
                                ? bookings.filter(booking => dayjs(booking.booking.dateOfPlay).isAfter(dayjs(), "day") || dayjs(booking.booking.dateOfPlay).isSame(dayjs(), "day")).map(booking => {
                                    return (
                                        <Sheet sx={{ borderRadius: '5px', p: 1.5 }} variant='outlined' onClick={() => setCurrentBooking(booking.booking)}>
                                            <Stack gap={0.2}>
                                                <Typography sx={{ ml: 0.3 }} level='title-lg'>{booking.booking.courseName}</Typography>
                                                <Stack direction='row' gap={2}>
                                                    <Typography startDecorator={<Icon icon='material-symbols:date-range' />}>{dayjs(booking.booking.dateOfPlay).format("MMM D, YYYY")}</Typography>
                                                    <Typography startDecorator={<Icon icon='mingcute:time-fill' />}>{booking.booking.time}</Typography>
                                                </Stack>
                                                <Typography level='body-sm'>{booking.booking.numberOfHolesBooked} holes | {booking.booking.bookingGroupSize} players x ${booking.booking.numberOfHolesBooked === 18 ? booking.booking.eighteenHolePrice : booking.booking.nineHolePrice}</Typography>
                                            </Stack>
                                        </Sheet>
                                    )
                                })
                                : null}

                            {/* // Empty state */}
                            {(!bookingsLoading && bookings?.filter(booking => dayjs(booking.booking.dateOfPlay).isAfter(dayjs(), "day") || dayjs(booking.booking.dateOfPlay).isSame(dayjs(), "day")).length === 0) ? <Typography level='body-sm'>You don't have any upcoming tee times.</Typography> : null}
                        </Stack>

                        <Typography level='title-lg' sx={{ mt: 2 }}>Past tee times</Typography>
                        <Stack sx={{ mt: 1 }} gap={1}>
                            {/* Loading state */}
                            {bookingsLoading ? <Stack direction='row' gap={1}><Typography level='body-sm'>Loading...</Typography><CircularProgress determinate={false} size='sm' color='neutral' /></Stack> : null}
                            {console.log(bookings.length)}
                            {bookings.length !== 0
                                ? bookings.filter(booking => dayjs(booking.booking.dateOfPlay).isBefore(dayjs(), "day")).map(booking => {
                                    return (
                                        <Sheet sx={{ borderRadius: '5px', p: 1.5 }} variant='outlined'>
                                            <Stack gap={0.5}>
                                                <Typography level='title-lg'>{booking.booking.courseName}</Typography>
                                                <Stack direction='row' gap={2}>
                                                    <Typography startDecorator={<Icon icon='material-symbols:date-range' />}>{dayjs(booking.booking.dateOfPlay).format("MMM D, YYYY")}</Typography>
                                                    <Typography startDecorator={<Icon icon='mingcute:time-fill' />}>{booking.booking.time}</Typography>
                                                </Stack>
                                                <Typography level='body-sm'>{booking.booking.numberOfHolesBooked} holes | {booking.booking.bookingGroupSize} players x ${booking.booking.numberOfHolesBooked === 18 ? booking.booking.eighteenHolePrice : booking.booking.nineHolePrice}</Typography>
                                            </Stack>
                                        </Sheet>
                                    )
                                })
                                : null}

                            {/* // Empty state */}
                            {(!bookingsLoading && bookings?.filter(booking => dayjs(booking.booking.dateOfPlay).isBefore(dayjs(), "day")).length === 0) ? <Typography level='body-sm'>You don't have any past tee times.</Typography> : null}
                        </Stack>
                    </Stack>

                    <Stack sx={{ flexGrow: 1 }}>
                        {/* <Typography level='title-lg'>My memberships</Typography> */}
                        {/* <Stack sx={{ mt: 1 }} gap={1}> */}
                        {/* Loading state */}
                        {/* {bookingsLoading ? <Stack direction='row' gap={1}><Typography level='body-sm'>Loading...</Typography><CircularProgress determinate={false} size='sm' color='neutral' /></Stack> : null} */}

                        {/* <Typography level='body-sm'>You don't have any memberships.</Typography> */}
                        {/* </Stack> */}
                    </Stack>
                </Stack>

                <Stack sx={{ flexGrow: 1 }}>
                    {/* <Typography level='title-lg'>My memberships</Typography>
                        <Stack sx={{ mt: 1 }}>
                            <Typography level='body-sm'>You don't have any memberships.</Typography>
                        </Stack> */}
                </Stack>
                {/* </UserDataProvider> */}
            </Container>
        </>
    )
}

export default UserDashboard;