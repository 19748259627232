import React, { useContext, useState } from 'react';
import { Button, FormControl, FormLabel, Input, Sheet, Snackbar, Stack, Typography } from '@mui/joy';
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet';
import bg from '../assets/login-bg.png';
import { AlertContext } from '../ui/AlertProvider';

const Login = () => {

    const [loggingIn, setLoggingIn] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false)
    const [error, setError] = useState(null)
    const { setAlert } = useContext(AlertContext)
    const auth = getAuth()

    const logIn = async (e) => {
        setLoggingIn(true)
        e.preventDefault()
        const email = e.target.elements.email.value
        const password = e.target.elements.password.value
        try {
            await signInWithEmailAndPassword(auth, email, password)
            setLoggingIn(false)
        } catch (e) {
            if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') { setError({ type: 'warning', message: "Invalid email or password" }) }
            else { setError({ type: "warning", message: "There was an unknown error" }) }
            setLoggingIn(false)
        }
    }

    const loginWithGoogle = async () => {
        setGoogleLoading(true)
        try {
            await signInWithPopup(auth, new GoogleAuthProvider())
            setGoogleLoading(false)
            setAlert({ type: "success", message: "Logged in!" })
        } catch (e) {
            setGoogleLoading(false)
            setAlert({ type: "warning", message: "Something went wrong." })
        }
    }


    return (
        <>
            <Helmet>
                <title>Log in to OpenSheet</title>
            </Helmet>
            {/* Snackbar to show alert messages */}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={Boolean(error)}
                onClose={() => setError(null)}
                autoHideDuration={3000}
                color={error?.type === "info" ? "primary" : error?.type === "warning" ? "warning" : "primary"}
                variant='soft'
                startDecorator={<Icon icon='ph:warning-fill' />}>
                {error?.message}
            </Snackbar>

            <Sheet sx={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <img src={bg} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, objectFit: 'cover', zIndex: 0 }} alt="" />

                <Stack sx={{ width: '100%', mx: 2, position: 'relative', zIndex: 5 }} justifyContent="center" alignItems="center">
                    <Sheet sx={{
                        px: 5,
                        py: 3,
                        maxWidth: '500px',
                        width: '100%',
                        borderRadius: '5px',
                        background: 'rgba(0, 0, 0, 0.19)',
                        backdropFilter: 'blur(13.949999809265137px)',
                    }}>
                        <Stack gap={2} sx={{ textAlign: 'center' }}>
                            <Typography level='h3' sx={{ color: 'white' }}>OpenSheet</Typography>

                            <Stack gap={0.75}>
                                <Button color='neutral' loading={googleLoading} variant='soft' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="flat-color-icons:google" />} onClick={loginWithGoogle}>Continue with Google</Button>
                                <Button color='neutral' disabled={(loggingIn || googleLoading)} variant='soft' sx={{ maxWidth: '100%' }} startDecorator={<Icon icon="logos:apple" />}>Continue with Apple</Button>
                            </Stack>

                            {/* Login form */}
                            <form action="" onSubmit={logIn}>
                                <Stack gap={2}>
                                    <FormControl required>
                                        <FormLabel sx={{ color: 'white' }}>Email address</FormLabel>
                                        <Input name='email' variant='outlined' sx={{ background: 'rgba(0, 0, 0, 0.20)', border: '1.5px solid rgba(255, 255, 255, 0.51)', "color": "white" }} type='email' placeholder="someone@someplace.com" />
                                    </FormControl>

                                    <FormControl required>
                                        <FormLabel sx={{ color: 'white' }}>Password</FormLabel>
                                        <Input name='password' variant='outlined' sx={{ background: 'rgba(0, 0, 0, 0.20)', border: '1.5px solid rgba(255, 255, 255, 0.51)', "color": "white" }} type='password' placeholder="Enter your password" />
                                    </FormControl>

                                    <Button type='submit' loading={loggingIn} disabled={(loggingIn || googleLoading)} color='main' sx={{ flexGrow: 1 }}>Log in</Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Sheet>
                </Stack>
            </Sheet>
        </>
    )
}

export default Login;